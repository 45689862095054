/*! _demo.scss | Bulkit | CSS Ninja */
@import '../../bulma/sass/utilities/mixins.sass';
/* ==========================================================================
Demo styles SCSS file (Theme index and components section, please avoid using this in production)
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Index styles
1. Component pages layout and elements
2. Flex card demo styles
3. Forms
4. Code accordion
5. Media elements
6. Demo Icons
7. Video Background
8. Navbar styles switcher
9. Bulkit color cards
10. Toast messages
11. Grid system demo
12. Parallax
13. Tags
14. Footer
15. Code Highlight
16. Iconpicker
17. Calendar
18. Media queries
=============================================================================
***/

/* ==========================================================================
0. Index styles
========================================================================== */

//Index hero shape
.hero.is-slanted:before {
    background: transparent url(../images/bg/shapes/slant.png) no-repeat scroll 0 (bottom / 100%) auto;
    content: "";
    height: 270px;
    width: 100%;
    z-index: 1 !important;
    position: absolute;
    bottom: 0;
    left: 0;
}

//Index footer Chart shape
.section.footer-chart:before {
    background: transparent url(../images/bg/shapes/footer-chart.svg) no-repeat scroll 0 (bottom / 100%) auto;
    content: "";
    height: 340px;
    width: 1600px;
    width: 100%;
    position: absolute;
    bottom: -1px;
    left: -1px;
    right: -1px;
    z-index: 0 !important;
}

//Index Laptop Hero
#main-landing-mockup {
    margin-top: -34rem;
}

//Index page title
#main-landing-title {
    position: relative;
    top: -110px;
    font-family: 'Nexa Bold', sans-serif !important;
    color: $white;

    h1 {
        position: relative;
        margin-bottom: 0 !important;
        font-weight: 600;
        text-transform: uppercase;

        &:after {
            content: '2.3';
            position: absolute;
            top: -8px;
            right: 40px;
            font-weight: 600;
            font-size: 1.5rem;
        }
    }
}

.translated {
    margin-top: -4.6rem;
    transform: translate3d(0, 100%, 0);
}

//Canvas
#demo-canvas {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
}

//Made with technologies
.made-with-wrapper {
    width: 100%;

    .made-with-title {
        font-size: 1.8rem;
        color: $title-grey;
        font-family: 'Nexa Light', sans-serif;
        max-width: 350px;
        margin: 0 auto;
        text-align: center;
        padding: 20px 0;
    }

    .made-with {
        max-width: 350px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            max-height: 65px;
            padding: 0 10px;
        }
    }
}

//Gradient
.hero.is-gradient {
    background: #00b289; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #8E54E9, #7F00FF); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #8E54E9, #7F00FF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.hero.is-gradient-green {
    background: #10B7A1; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #10B7A1, rgb(16, 183, 169)); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #10B7A1, rgb(16, 183, 169)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

//Tabs holding demo pages
#demo-tabs {
    ul li {
        font-size: 1rem;

        &.is-active a {
            color: $secondary;
            border-color: $secondary;
        }
    }
}

//Demo page item
.demo-wrap {
    .demo-wrapper {
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);

        &:hover {
            .circle-overlay {
                transform: scale(35);
            }

            .demo-title {
                opacity: 1;
                transform: translateY(0);
                transition-delay: 0 !important;
            }

            .demo-link {
                color: $white;
                opacity: 1;
                transform: translateY(0);
                transition-delay: 0 !important;
            }
        }

        img {
            border-radius: 10px;
            display: block;
        }

        .circle-overlay {
            position: absolute;
            top: -60px;
            left: -60px;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background: $primary;
            transform: scale(1);
            transition: all 0.5s;
            z-index: 1;

            &.is-landing {
                background: $secondary;
            }

            &.is-landing-v1 {
                background: $green;
            }

            &.is-landing-v2 {
                background: #0082c8;
            }

            &.is-landing-v3, &.is-landing-v5 {
                background: $blue;
            }

            &.is-landing-v6 {
                background: #0f7cff;
            }

            &.is-agency, &.is-dashboard {
                background: #00D1B2;
            }
        }

        .demo-info {
            position: absolute;
            margin: 0 auto;
            top: 35%;
            left: 0;
            right: 0;
            z-index: 2;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .demo-title {
            color: $white;
            font-family: 'Nexa Bold', sans-serif;
            font-size: 2.2rem;
            font-weight: 600;
            padding-bottom: 0;
            opacity: 0;
            transform: translateY(20px);
            transition: all 0.3s;
            transition-delay: 0.1s;
        }

        .demo-link {
            opacity: 0;
            transform: translateY(20px);
            transition: all 0.3s;
            transition-delay: 0.2s;

            a {
                color: $white;
                font-size: 1.2rem;

                i {
                    position: relative;
                    top: 8px;
                    opacity: 0;
                    transform: translateX(0);
                    transition: all 0.3s ease-out;
                }

                &:hover i {
                    opacity: 1;
                    transform: translateX(10px);
                }
            }
        }
    }
}

//Component boxes
.component-box {
    text-align: center;
    margin-bottom: 20px;

    .component img {
        max-height: 100px;
    }

    .component-title {
        font-size: 1.2rem;
        font-weight: 500;
        color: $blue-grey;

        a {
            color: $secondary;

            &:hover {
                color: $primary;
            }
        }
    }
}

//Browser compatibility
.browsers {
    .browser-block {
        text-align: center;
        max-width: 150px;
        margin: 0 auto;

        .browser-icon {
            height: 56px !important;
            margin: 10px 0;
        }

        .browser-name {
            background: $white;
            color: $muted-grey;
            font-weight: 500;
            padding: 10px 15px;
            margin: 0;
            border-radius: 8px;
            border: 1px solid $fade-grey;
        }
    }
}

//Index footer
.footer-light.is-tainted {
    background: $secondary;
    color: $white;
    position: relative;

    .columns {
        margin-top: 35px;
    }

    .footer-logo {
        img {
            height: 25px;
        }
    }

    .footer-column {
        .footer-header h3 {
            font-weight: 400;
            font-size: 1.1rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin-bottom: 10px;
            color: $white;
        }

        ul.link-list {
            line-height: 30px;
            font-size: 1rem;

            a {
                color: $white;
                font-weight: 400;
                transition: all 0.5s;
            }

            :hover {
                color: $white;
            }
        }

        .level-item .icon {
            color: $white;
            transition: all 0.5s;

            :hover {
                color: $white;
                opacity: 0.8;
            }
        }
    }

    .made-with-logo {
        margin: 10px 0;

        img {
            max-height: 32px;
        }
    }
}

.tag {
    line-height: 0 !important;

    &.no-margin {
        margin: 0 !important;
    }
}

/* ==========================================================================
1. Component pages layout and elements
========================================================================== */

//Components section hero title
.components-title, .handwritten-title, .handwritten-text {
    font-family: 'Nexa Bold', cursive;

    &.is-cta {
        font-size: 3.4rem !important;
    }
}

.components-title {
    margin-bottom: 0 !important;
    padding: 10px 0;
}

//Components section hero subtitle
.components-subtitle {
    margin-bottom: 0 !important;
    padding: 20px 0;
    font-family: 'Nexa Light', sans-serif;
}

// Components sections info blocks
.info-block {
    margin: 1rem;

    h4 {
        font-size: 1.4rem;
        padding: 15px 0;
        color: $blue-grey;
        font-family: 'Nexa Bold', sans-serif;
    }

    p {
        padding: 10px 0;
    }
}

//Spacer
.spacer-40 {
    margin-bottom: 40px;
}

//Hero heading
.hero-heading {
    font-size: 3rem !important;
    font-family: Open Sans, sans-serif !important;

    + h2 {
        padding: 0;
        margin-bottom: 0 !important;
    }
}

.section-title {
    font-family: 'Open Sans', sans-serif;
}

//Demo section title adjustment
.section-title.is-demo {
    font-weight: 700;
    font-size: 2rem !important;
}

//Components hero svg
.vivus-svg {
    max-height: 450px;
    width: 450px;
}

//Feature icon
.feature-icon {
    height: 140px !important;
}

.feature-icon-md {
    height: 110px !important;
}

.feature-icon-sm {
    height: 90px !important;
}

//Feature description
.feature-description {
    font-size: 1.2rem;
    color: $title-grey;

    span {
        display: block;

        &.feature-key {
            font-size: 1.2rem;
            font-weight: 600;
            color: $secondary;
            font-family: 'Nexa Light', sans-serif;
        }
    }
}

//Code highlight
.highlight {
    padding: 1.4rem;
    overflow: auto;
    background: #444F60;
    margin-top: 2.8rem;
    margin-bottom: 2.8rem;
    border-radius: 4px;
}

.highlight::-webkit-scrollbar {
    width: 10px;
}

.highlight::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
}

code.hljs span {
    font-family: Inconsolata, monospace !important;
}

//Media queries (mobile)
@media (max-width: 767px) {
    .components-title, .components-subtitle, .components-cta {
        text-align: center !important;
    }
}

/* ==========================================================================
2. Flex card demo styles
========================================================================== */
.flex-card {
    &.is-active {
        .name {
            color: $primary !important;
        }
    }

    .card-avatar img {
        width: 45px;
        border-radius: 100px;
    }

    .card-name {
        margin-left: 15px;

        .name {
            color: $blue-grey;
        }

        .position {
            font-size: 0.9rem;
            color: $title-grey;
        }
    }

    .card-select i {
        color: $placeholder;
        border: 2px dashed $placeholder;
        border-radius: 100px;
        padding: 6px;
        font-size: 20px;
        transition: all 0.5s;

        &:hover, &.is-active {
            color: $primary;
            border: 2px solid $primary;
        }
    }

    .title-bold {
        font-family: 'Nexa Bold', sans-serif;
    }

    .title-light {
        font-family: 'Nexa Light', sans-serif;
    }
}

//Feature cards
.feature-card {
    padding: 20px !important;

    img {
        margin: 0 !important;
    }
}

/* ==========================================================================
3. Forms
========================================================================== */
form {
    width: 100%;
}

//Demo inputs width
.input.demo-input, .control.demo-input {
    width: 80%;
}

.field.has-addons {
    display: flex;

    input {
        height: 32px;
        margin-right: 5px;
    }
}

/* ==========================================================================
4. Code accordion
========================================================================== */
.accordion-wrapper {
    .toggle-wrap {
        border-bottom: 1px solid #e0e0e0;

        .trigger a {
            display: block;
            cursor: pointer;
            position: relative;
            padding: 0;
            line-height: 26px;
            outline: none;
            color: $blue-grey;
            font-size: 14px;
            background-color: #fff;
            border: 1px solid #e0e0e0;
            transition: background-color 0.2s, color 0.2s;
            padding: 16px 27px;
            margin: -1px 0 0 0;

            i {
                padding-right: 6px;
                margin: 0 4px 0 0;
                transition: all 0.3s;
            }

            .im-icon-Add {
                position: absolute;
                right: 0;
                top: 55%;
                font-size: 24px;
                transform: translate3d(0, -50%, 0) rotate(0);
                transition: 0.2s;
            }

            .im-icon-Coding {
                position: relative;
                top: 7px;
            }
        }

        .trigger a {
            text-transform: none;
            border: none;
            margin: 0;
            padding: 15px 0;
            text-transform: none;
            font-size: 11px;
            font-weight: 500;
            background-color: transparent;
            color: $accent;
            padding-right: 60px;
            font-weight: 400;
        }

        .trigger.active .im-icon-Add {
            margin-top: 2px;
            transform: translate3d(0, -50%, 0) rotate(45deg);
            color: $accent;
        }

        .toggle-container {
            padding: 0px 0 30px 0;
            border: none;
        }
    }
}

/* ==========================================================================
5. Media elements
========================================================================== */

//Notifications items
.media.media-notification {
    align-content: center;
    cursor: pointer;

    &:hover {
        .notification-title {
            color: $primary;
        }
    }

    .timestamp {
        color: $title-grey;
        position: relative;
        top: 3px;
        font-size: 0.7rem;
    }

    .notification-title {
        font-weight: 600;
    }

    .notification-text {
        font-size: 0.9rem !important;
        color: $basaltic-grey;
    }
}

.see-all {
    margin-top: 20px;
    font-size: 0.7rem;
    text-transform: uppercase;
}

//Icon medias
.media.icon-media {
    align-content: center;
    align-items: center;
    cursor: pointer;

    .media-left i {
        font-size: 2.6rem;
        color: $title-grey;
    }

    .timestamp {
        color: $title-grey;
        position: relative;
        top: 3px;
        font-size: 0.7rem;
    }

    .notification-title {
        font-weight: 600;
    }

    .notification-text {
        font-size: 0.9rem !important;
        color: $basaltic-grey;
    }
}

/* ==========================================================================
6. Demo Icons
========================================================================== */

//Icons mind collection item
.demo-im-block {
    &:hover {
        background: $light-grey;

        .demo-im i {
            transform: scale(1.1);
            color: $accent;
        }
    }

    .demo-im i {
        font-size: 2.6rem;
        margin: 0 15px 0 10px;
        color: $basaltic-grey;
        transition: all 0.4s;
    }

    .im-code {
        font-size: 1rem;

        code {
            color: $basaltic-grey;
        }
    }
}

//Font Awesome collection item
.demo-fa-block {
    padding: 10px 5px;

    &:hover {
        background: $light-grey;

        .demo-fa i {
            transform: scale(1.1);
            color: $accent;
        }
    }

    .demo-fa i {
        font-size: 1.6rem;
        margin: 0 15px 0 10px;
        color: $basaltic-grey;
        transition: all 0.4s;
    }

    .fa-embed-code {
        font-size: 1rem;

        code {
            color: $basaltic-grey;
        }
    }
}

//Simple Line Icons collection item
.demo-sl-block {
    padding: 10px 5px;

    &:hover {
        background: $light-grey;

        .demo-sl i {
            transform: scale(1.1);
            color: $accent;
        }
    }

    .demo-sl i {
        font-size: 1.6rem;
        margin: 0 15px 0 10px;
        color: $basaltic-grey;
        transition: all 0.4s;
    }

    .sl-embed-code {
        font-size: 1rem;

        code {
            color: $basaltic-grey;
        }
    }
}

/* ==========================================================================
7. Video Background
========================================================================== */
.covervid-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .videobg-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($primary, 0.5);
        z-index: 1;
    }

    .video-caption {
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        z-index: 3;

        .content {
            position: relative;
            top: 35%;
        }

        h1 {
            font-family: 'Nexa Bold', sans-serif;
            font-size: 3.5rem;
            font-weight: 600;
            margin-bottom: 10px;
        }

        p {
            font-size: 1.5rem;
            font-family: 'Nexa Light', sans-serif;
        }
    }

    video {
        display: inline-block;
        max-width: none !important;
    }
}

/* ==========================================================================
8. Navbar styles switcher
========================================================================== */

//Navbar inverse selector
.nav-switch {
    padding: 20px;
    position: fixed;
    right: 40px;
    bottom: 40px;
    background: lighten($sidebar, 5%);
    border: 1px solid $fade-grey;
    border-radius: 4px;
    min-width: 220px;

    .panel-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
            text-transform: uppercase;
            color: $white;
        }
    }

    .panel-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;

        .color-dot {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 1px solid $fade-grey;
            margin-top: 10px;
            cursor: pointer;

            &.is-white {
                background: $white;
            }

            &.is-blue {
                background: $blue;
            }

            &.is-purple {
                background: $secondary;
            }

            &.is-red {
                background: $red;
            }

            &.is-reverse {
                background: $sidebar;
            }
        }
    }
}

/* ==========================================================================
9. Bulkit color cards
========================================================================== */
.flex-card.color-card {
    .color-item {
        display: flex;
        align-items: center;

        .color-spot {
            display: block;
            width: 40px !important;
            height: 40px !important;
            border: 1px solid $fade-grey;
            border-radius: 100px;

            &.is-white {
                background: $white;
            }

            &.is-smoke-white {
                background: $smoke-white;
            }

            &.is-grey-white {
                background: $grey-white;
            }

            &.is-grey-white-accent {
                background: $grey-white-accent;
            }

            &.is-light-grey {
                background: $light-grey;
            }

            &.is-ultra-light-grey {
                background: $ultra-light-grey;
            }

            &.is-title-grey {
                background: $title-grey;
            }

            &.is-blue-grey {
                background: $blue-grey;
            }

            &.is-light-blue-grey {
                background: $light-blue-grey;
            }

            &.is-medium-grey {
                background: $medium-grey;
            }

            &.is-basaltic-grey {
                background: $basaltic-grey;
            }

            &.is-muted-grey {
                background: $muted-grey;
            }

            &.is-section-grey {
                background: $section-grey;
            }

            &.is-section-grey-accent {
                background: $section-grey-accent;
            }

            &.is-header-grey {
                background: $header-grey;
            }

            &.is-default-input {
                background: $default-input;
            }

            &.is-placeholder {
                background: $placeholder;
            }

            &.is-fade-grey {
                background: $fade-grey;
            }

            &.is-sidebar {
                background: $sidebar;
            }

            &.is-coal {
                background: $coal;
            }

            &.is-grey-3 {
                background: $grey-3;
            }

            &.is-grey-5 {
                background: $grey-5;
            }

            &.is-grey-6 {
                background: $grey-6;
            }

            &.is-cream-grey {
                background: $cream-grey;
            }

            &.is-icon-grey {
                background: $icon-grey;
            }

            &.is-blue {
                background: $blue;
            }

            &.is-green {
                background: $green;
            }

            &.is-orange {
                background: $orange;
            }

            &.is-red {
                background: $red;
            }

            &.is-facebook {
                background: $facebook;
            }

            &.is-twitter {
                background: $twitter;
            }

            &.is-linkedin {
                background: $linkedin;
            }

            &.is-tumblr {
                background: $tumblr;
            }

            &.is-reddit {
                background: $reddit;
            }

            &.is-github {
                background: $github;
            }

            &.is-dribbble {
                background: $dribbble;
            }

            &.is-youtube {
                background: $youtube;
            }

            &.is-google-plus {
                background: $google-plus;
            }

            &.is-primary {
                background: $primary;
            }

            &.is-secondary {
                background: $secondary;
            }

            &.is-accent {
                background: $accent;
            }

            &.is-green-accent {
                background: #06f4b6;
            }

            &.is-deep-primary {
                background: #0082c8;
            }

            &.is-deep-secondary {
                background: #7c4dff;
            }

            &.is-deep-accent {
                background: #00b289;
            }

            &.is-lemonade-primary {
                background: #039BE5;
            }

            &.is-lemonade-secondary {
                background: #0084c4;
            }

            &.is-lemonade-accent {
                background: #00D1B2;
            }

            &.is-dashboard-primary {
                background: #00D1B2;
            }

            &.is-dashboard-secondary {
                background: #7F00FF;
            }

            &.is-dashboard-accent {
                background: #536dfe;
            }
        }

        .color-values {
            margin: 0 20px;

            .hex-value {
                color: $muted-grey;

                span {
                    font-weight: 600;
                    color: $blue-grey;
                }
            }

            .variable-value span {
                color: $accent;
                font-weight: 600;
            }
        }
    }
}

/* ==========================================================================
10. Toast messages
========================================================================== */
.iziToast-cover {
    width: 60px !important;
    background-size: cover !important;
}

/* ==========================================================================
11. Grid system demo
========================================================================== */
.grid-system {
    .columns {
        .column p {
            padding: 40px;
            font-size: 1.2rem;
            font-weight: 600;
            color: $white;
            text-align: center;
            border-radius: 4px;

            &.is-blue {
                background: $blue;
            }

            &.is-green {
                background: $green;
            }

            &.is-orange {
                background: $orange;
            }

            &.is-red {
                background: $red;
            }
        }
    }

    &.is-medium {
        .column p {
            padding: 25px;
        }
    }
}

/* ==========================================================================
12. Parallax
========================================================================== */

//Parallax hero title
.parallax-hero-title {
    font-size: 6rem !important;
    font-family: 'Nexa Bold', cursive  !important;

    + h2 {
        padding: 0 20%;
        margin-bottom: 0 !important;
    }
}

/* ==========================================================================
13. Tags
========================================================================== */
.tag.is-bold {
    border-width: 2px !important;
    font-weight: 700 !important;
}

/* ==========================================================================
14. Footer
========================================================================== */

//Small footer logo
.small-footer-logo {
    height: 38px;
}

//More info
span.more-info {
    font-size: 22px;
}

//More info email
span.more-info-email {
    font-size: 26px;
    margin-top: 20px;
    color: $primary;
}

/* ==========================================================================
15. Code highlight
========================================================================== */

pre {
    background-color: transparent !important;
    color: unset;
    font-size: 0.875em;
    overflow-x: auto;
    padding: 0 !important;
    white-space: pre;
    word-wrap: normal;
}

.hljs {
    display: block;
    overflow-x: auto;
    padding: 1.5em;
    background: #444F60;
    color: white;
}

.hljs-name,
.hljs-strong {
    font-weight: bold;
    color: #4FC1EA;
}

.hljs-code,
.hljs-emphasis {
    font-style: italic;
}

.hljs-tag {
    color: #909090;
}

.hljs-variable,
.hljs-template-variable,
.hljs-selector-id,
.hljs-selector-class {
    color: #ade5fc;
}

.hljs-string,
.hljs-bullet {
    color: #F39200 !important;
}

.hljs-type,
.hljs-title,
.hljs-section,
.hljs-attribute,
.hljs-quote,
.hljs-built_in,
.hljs-builtin-name {
    color: #ffa;
}

.hljs-attr {
    color: #68BB88;
}

.hljs-number,
.hljs-symbol,
.hljs-bullet {
    color: #d36363;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-literal {
    color: #fcc28c;
}

.hljs-comment,
.hljs-deletion,
.hljs-code {
    color: #888;
}

.hljs-regexp,
.hljs-link {
    color: #c6b4f0;
}

.hljs-meta {
    color: #fc9b9b;
}

.hljs-deletion {
    background-color: #fc9b9b;
    color: #333;
}

.hljs-addition {
    background-color: #a2fca2;
    color: #333;
}

.hljs a {
    color: inherit;
}

.hljs a:focus,
.hljs a:hover {
    color: inherit;
    text-decoration: underline;
}

/* ==========================================================================
16. Icon Picker
========================================================================== */

.iconpicker-wrapper {
    padding: 20px;
    text-align: center;
    max-width: 300px;
    margin: 0 auto;
    border: 2px solid transparent;
    border-radius: 4px;
    transition: all .3s;

    input {}

    .help {
        padding-top: 10px;
        font-weight: 500;
    }

    .iconpicker-preview {
        background: $blue;
        transition: all .3s;

        i {
            color: $white;
        }

        &:hover {
            background: $secondary;
        }
    }

    &:hover {
        border: 2px solid $secondary;
    }
}

.iconpicker-modal {
    .iconpicker-icons {
        a {
            color: $muted-grey;

            &:hover {
                color: $secondary;
            }

            &:before {
                content: '' !important;
            }
        }
    }

    &.is-active {
        .modal-background {
            display: block !important;
            width: 100vw !important;
            height: 100vh !important;
            min-width: 100vw !important;
            min-height: 100vh !important;
            position: fixed !important;
            top: 0 !important;
            left: 0 !important;
            background: rgba(0, 0, 0, 0.8) !important;
            transform: scale(1) !important;
            border-radius: 0 !important;
        }

        .modal-card {
            z-index: 99 !important;

            .modal-card-head {
                background: $blue;

                .modal-card-title {
                    color: $white;
                    font-weight: 500;
                }

                .iconpicker-search {
                    background: $white;
                    color: $blue-grey;
                    transition: all .3s;

                    &:focus {
                        outline: none !important;
                    }
                }

                .delete {
                    &:before, &:after {
                        background: $white !important;
                    }
                }
            }

            .iconpicker-sets.tabs {
                ul li {
                    a {
                        border-radius: 0 !important;
                    }

                    &.is-active {
                        a {
                            color: $secondary;
                            border-bottom: 1.4px solid $secondary;
                        }
                    }
                }
            }
        }
    }
}

/* ==========================================================================
17. Calendar
========================================================================== */

.calendar {
    &.is-centered {
        margin: 0 auto;
    }

    &.is-large {
        .calendar-range {
            &:before {
                top: 1.6rem !important;
            }
        }
    }

    &.is-custom {
        border: 1px solid $fade-grey;
        border-radius: 3px;

        .calendar-nav {
            background: $secondary;
        }

        .calendar-nav-previous-month, .calendar-nav-next-month {
            button {
                text-decoration: none !important;

                i {
                    color: $white !important;
                }

                &:hover {
                    background: transparent !important;
                }
            }
        }

        .calendar-body {
            .date-item {
                &.is-today {
                    border-color: $secondary;
                    color: $secondary;
                }

                &.is-active {
                    background: $secondary;
                    color: $white !important;
                    border-color: $secondary;
                }
            }

            .calendar-range {
                .date-item {
                    color: $secondary;
                }

                &:before {
                    background: lighten($secondary, 40%);
                }
            }
        }

        button:focus, button:active {
            background: transparent !important;
            border-color: transparent !important;
        }
    }

    .calendar-event {
        background: $muted-grey;
        padding: .6rem .4rem !important;

        &.is-primary {
            background: $primary !important;
        }

        &.is-secondary {
            background: $secondary !important;
        }

        &.is-accent {
            background: $accent !important;
        }

        &.is-success {
            background: $green !important;
        }

        &.is-warning {
            background: $orange !important;
            color: $white !important;
        }

        &.is-danger {
            background: $red !important;
        }
    }
}

.datepicker.modal, .datepicker {
    &.is-active {
        .modal-background {
            display: block !important;
            width: 100vw !important;
            height: 100vh !important;
            min-width: 100vw !important;
            min-height: 100vh !important;
            position: fixed !important;
            top: 0 !important;
            left: 0 !important;
            background: rgba(0, 0, 0, 0.8) !important;
            transform: scale(1) !important;
            border-radius: 0 !important;
        }

        .calendar {
            z-index: 99 !important;

            .calendar-nav {
                background: $secondary;

                button:focus, button:active {
                    background: transparent !important;
                    border-color: transparent !important;
                }
            }

            .calendar-body {
                min-height: 266px !important;

                .date-item {
                    &.is-active {
                        background: $secondary;
                        border-color: $secondary;
                        color: $white;
                    }
                }
            }
        }
    }
}

.datepicker {
    .calendar {
        border: 1px solid $fade-grey;
    }
}

/* ==========================================================================
18. Media queries
========================================================================== */

//Mobile
@media (max-width: 767px) {
    #main-landing-title {
        top: -60px;

        h1 {
            &:after {
                right: 3px;
            }
        }
    }

    #main-landing-mockup {
        margin-top: -16rem;
    }

    .parallax-hero-title {
        font-size: 4rem !important;

        + h2 {
            padding: 0 8%;
            margin-bottom: 0 !important;
        }
    }
}

//Tablet orientation portrait
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    #main-landing-mockup {
        margin-top: -28rem;
    }
}

//Tablet orientation Landscape
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    #main-landing-title {

        h1 {
            &:after {
                right: 3px;
            }
        }
    }
}

/* ==========================================================================
18. Custom
========================================================================== */
@font-face {
    font-family: 'Alba';
    src:  url('assets/images/fonts/Alba.ttf.woff') format('woff'),
    url('assets/images/fonts/Alba.ttf.svg#Alba') format('svg'),
    url('assets/images/fonts/Alba.ttf.eot'),
    url('assets/images/fonts/Alba.eot?#iefix') format('embedded-opentype'); 
    font-weight: normal;
    font-style: normal;
}
.is-logo-text {
    font-family: 'Alba' !important;
    text-transform: lowercase !important;
    &.title {
        font-size: 4rem;
    }
}
img.mock-img-1 {
    margin: 0 auto;
    width: 50%;
}
.mt10 {
    margin-top: 10rem;
}
.store-link {
    display: inline;
    margin: 0 2rem;
}
.ma {
    margin: 0 auto;
}
img.eatlogo {
        max-height: inherit;
}
h1.no-uppercase {
    text-transform: inherit !important;
}
.w90 {
    width: 90%;
    margin: 0 auto;
    display: block;
}
.front {
    z-index: 1;
    position: relative;
}
.title-divider.centered {
    margin: 10px auto;
}
figure.team-slanted figcaption.green::before {
    background: rgba(16, 183, 161, 0.8);
}

figure.team-slanted p a i {
    color: #fff;
}

figure.team-slanted {
    max-width: 370px;
    margin: 0 auto;
}
footer.footer-light-left .footer-nav-right .footer-nav-link {
    font-size: 1.3rem;
    color: #fff !important;
    &:hover {
        color: #fff !important;
        border-bottom: 2px solid #fff;
    }
}

footer.footer-light-left .level-item .icon {
    color: #fff;
    font-size: 1.3rem;
    &:hover i {
        color: #fff;
        font-size: 1.4rem;
    }
}
.navbar-item, .navbar-link {
    border-bottom: solid 3px transparent;
    color: #10B7A1;
}

.navbar-wrapper .navbar-item:hover,
.navbar-wrapper .navbar-item.has-dropdown:hover {
    color: #10B7A1;
    transition: all 0.3s;
    border-bottom: solid 3px #10B7A1;
}
.subheading {
    text-align: center;
    font-weight: 600;
    max-width: 70vh;
    margin: 0 auto;
    padding: 10px 20px;
    font-size: 18px;
    color: #A9ABAC;
}
.hero-body {
    background-color: #10B7A1;
}
.card-team .card-team-item .member-id .position {
    font-size: 1rem;
    font-weight: bold;
}
.image img.splash {
    width: 300px;
    margin: 0 auto;
}
.floating-avatars .avatar.av-icon {
    border-radius: 0;
}
.navbar-wrapper .navbar-brand img,
.navbar-wrapper.navbar-faded .navbar-brand img {
    width: 90px;
    height: auto;
    display: block;
    margin: 0 auto;
    background: #10B7A1;
    border-radius: 4rem;
}
.navbar-item img {
    max-height: inherit;
}
.navbar-wrapper.navbar-faded {
}
.navbar-wrapper .navbar-item:hover,
.navbar-wrapper .navbar-item.has-dropdown:hover {
    border: none;
}
.navbar-menu {
}
.department {
    color: #A9ABAC;
}
.overlay {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    background:rgba(0, 0, 0, 0.7);
}
.is-relative {
    z-index: 1;
    position: relative;
}
.z1 {
    z-index: 1;
}
#main-hero {
    margin-top: -1rem;
}
.is-alba {
    text-transform: lowercase;
    font-family: 'Alba', Arial, Helvetica, sans-serif !important;
}
.hero.is-theme-primary .subtitle {
    font-size: 2rem;
}
.btn-download {
    font-size: 1.5rem;
}
.fh {
    min-height: 100vh;
}

.section .title-divider.is-30 {
    width: 30%;
}
.hero-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: all 0.5s;
    background:rgba(16, 183, 161, 0.7);
}
.hero-select {
    text-align: center;
    & h2 {
        color: #fff;
        z-index: 1;
        position: absolute;
        top: 100%;
        width: 100%;
        transition: all 0.5s;
        font-size: 5rem;
        margin-top: -2.5rem;
        text-shadow:2px 2px 3px rgba(0,0,0,0.3);
        font-family: 'Quicksand', sans-serif;
    }
}
.hero-select:hover {
    cursor: pointer;
    .hero-overlay {
        opacity: 1;
    }
    & h2 {
        top: 50%;
    }
}
.section-stand {
    z-index: 0;
    position: relative;
    overflow: hidden;
}
.is-logo-text.title {
    color: #fff;
}
.hero-video video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
}
.hero-body {
    background: transparent;
}
.logo-inline {
    width: 8rem;
    margin-bottom: 0;
    margin-left: 1rem;
}
.logo-init {
    width: 15rem;
}
.hero-caption .title.big-landing-title {
    color: #fff !important;
}
.o-visible {
    overflow: visible !important;
}
.z1 {
    z-index: 1;
}
.input-mail {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    &::placeholder {
        color: #cecece;
        font-size: 1.1rem;
    }
}
.section-contact {
    margin-top: 6rem;
}